import axios from 'axios';
import {CART_EMPTY} from '../constans/cartConstans';
import {ORDER_CANCEL_LIST_FAIL, ORDER_CANCEL_LIST_REQUEST, ORDER_CANCEL_LIST_SUCCESS, ORDER_CONFIRM_LIST_FAIL, ORDER_CONFIRM_LIST_REQUEST, ORDER_CONFIRM_LIST_SUCCESS, ORDER_CREATE_FAIL, ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_DELETE_LIST_FAIL, ORDER_DELETE_LIST_REQUEST, ORDER_DELETE_LIST_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_HISTORY_LIST_FAIL, ORDER_HISTORY_LIST_REQUEST, ORDER_HISTORY_LIST_SUCCESS, ORDER_MINE_LIST_FAIL, ORDER_MINE_LIST_REQUEST, ORDER_MINE_LIST_SUCCESS} from '../constans/orderConstans';

export const createOrder = (order) => async (dispatch, getState) => {

    dispatch({ type: ORDER_CREATE_REQUEST, payload: order });
    try {
      const {
        userSignin: { userInfo },
      } = getState();
      const { data } = await axios.post('/api/orders', order, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.order });
      dispatch({ type: CART_EMPTY });
      localStorage.removeItem('cartItems');
    } catch (error) {
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }

}

export const detailsOrder = (orderId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DETAILS_REQUEST, payload: orderId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/orders/${orderId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DETAILS_FAIL, payload: message });
  }
};

export const listOrderMine = () => async (dispatch, getState) => {
  dispatch({ type: ORDER_MINE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get('/api/orders/mine', {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });
    dispatch({ type: ORDER_MINE_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_MINE_LIST_FAIL, payload: message });
  }
};

export const listOrderHistory = (userId) => async (dispatch, getState) => {
  dispatch({ type: ORDER_HISTORY_LIST_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get('/api/orders/history_order', {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: ORDER_HISTORY_LIST_SUCCESS, payload: data});
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_HISTORY_LIST_FAIL, payload: message });
  }
};

export const orderCancelBD = (order) => async (dispatch) => {

  dispatch({ type: ORDER_CANCEL_LIST_REQUEST, payload: { order } });
  try {
    const { data } = await axios.patch('/api/orders/cancel_order', {
      order
    });
    dispatch({ type: ORDER_CANCEL_LIST_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: ORDER_CANCEL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }

}

export const confirmOrder = (id) => async (dispatch) => {
  
    dispatch({ type: ORDER_CONFIRM_LIST_REQUEST, payload: { id } });
    try {
      const { data } = await axios.patch('/api/orders/confirm_order', {
        id
      });
      dispatch({ type: ORDER_CONFIRM_LIST_SUCCESS, payload: data });
     
    } catch (error) {
      dispatch({
        type: ORDER_CONFIRM_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  
} 

export const orderDeleteBD = (id) => async (dispatch) => {

  dispatch({ type: ORDER_DELETE_LIST_REQUEST, payload: { id } });
  try {
    const { data } = await axios.patch('/api/orders/delete_order', {
      id
    });
    dispatch({ type: ORDER_DELETE_LIST_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: ORDER_DELETE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }

}