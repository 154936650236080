import axios from 'axios';
import {PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS,
PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_FILTER_LIST_REQUEST, PRODUCT_FILTER_LIST_SUCCESS, PRODUCT_FILTER_LIST_FAIL, PRODUCT_ADD_LIST_REQUEST, PRODUCT_ADD_LIST_SUCCESS, PRODUCT_ADD_LIST_FAIL, PRODUCT_UPDATE_LIST_SUCCESS, PRODUCT_UPDATE_LIST_REQUEST, PRODUCT_UPDATE_LIST_FAIL, PRODUCT_DELETE_LIST_REQUEST, PRODUCT_DELETE_LIST_SUCCESS, PRODUCT_DELETE_LIST_FAIL, PRODUCT_DELETE_ALL_LIST_REQUEST, PRODUCT_DELETE_ALL_LIST_SUCCESS, PRODUCT_DELETE_ALL_LIST_FAIL } from '../constans/productConstans';

export const listProducts = () => async (dispatch) => {
  dispatch({type: PRODUCT_LIST_REQUEST});
  try {
     const {data} = await axios.get('/api/products');
     data.reverse();
     dispatch({type: PRODUCT_LIST_SUCCESS, payload: data})
  } catch(err) {
    dispatch({type: PRODUCT_LIST_FAIL, payload: err.message})
  }
}

export const filterProductsAction = (sizesValue, pricesValue) => async (dispatch) => {
  dispatch({
    type: PRODUCT_FILTER_LIST_REQUEST
  });
  try {
     const {data} = await axios.post('/api/products/filter', {sizesValue, pricesValue});
  dispatch({type: PRODUCT_FILTER_LIST_SUCCESS, payload: data})
} catch(err) {
    dispatch({type: PRODUCT_FILTER_LIST_FAIL, payload: err.message})
  }

}

export const detailsProduct = (productId) => async (dispatch) => {
  dispatch({
    type: PRODUCT_DETAILS_REQUEST, payload: productId
  });
  try {
     const {data} = await axios.get(`/api/products/${productId}`);
     dispatch({type: PRODUCT_DETAILS_SUCCESS, payload: data})
  } catch(err) {
    dispatch({type: PRODUCT_DETAILS_FAIL, payload: err.response && err.response.data.message ? err.response.data.message 
    : err.message})
  }
}

export const productsAddBD = ({category, src, name, article, color, oldPrice, newPrice, composition, size, recomendationProduct}) => async (dispatch) => {
  dispatch({ type: PRODUCT_ADD_LIST_REQUEST, payload: { category, src, name, article, color, oldPrice, newPrice, composition, size, recomendationProduct } });
  try {
    const { data } = await axios.post('/api/products/create_product', {
      category, 
      src, 
      name, 
      article, 
      color, 
      oldPrice, 
      newPrice, 
      composition, 
      size,
      recomendationProduct
    });
    dispatch({ type: PRODUCT_ADD_LIST_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: PRODUCT_ADD_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const productsUpdateBD = ({category, src, name, article, color, oldPrice, newPrice, composition, size, recomendationProduct}) => async (dispatch) => {

  dispatch({ type: PRODUCT_UPDATE_LIST_REQUEST, payload: { category, src, name, article, color, oldPrice, newPrice, composition, size, recomendationProduct } });
  try {
    const { data } = await axios.put('/api/products/update_product', {
      category, 
      src, 
      name, 
      article, 
      color, 
      oldPrice, 
      newPrice, 
      composition, 
      size,
      recomendationProduct
    });
    dispatch({ type: PRODUCT_UPDATE_LIST_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const productsRemoveSizeBD = ({article, color, size}) => async (dispatch) => {

  dispatch({ type: PRODUCT_DELETE_LIST_REQUEST, payload: { article, color, size } });
  try {
    const { data } = await axios.patch('/api/products/delete_product', {
      article, 
      color, 
      size
    });
    dispatch({ type: PRODUCT_DELETE_LIST_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const productsRemoveBD = (id) => async (dispatch) => {

  dispatch({ type: PRODUCT_DELETE_ALL_LIST_REQUEST, payload: { id } });
  try {
    const { data } = await axios.patch('/api/products/delete_all_product', {
      id
    });
    dispatch({ type: PRODUCT_DELETE_ALL_LIST_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_ALL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}