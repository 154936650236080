import React, { useEffect, useState, useMemo } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import { addToCart } from '../actions/cartActions';
import {detailsProduct} from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

export default function ProductScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const { loading, error, product } = useSelector(state => state.productDetails);
  const { products } = useSelector(state => state.productList); 
  let [mySize, setMySize] = useState('');
  let [checkClick, setCheckClick] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect( () => {
  dispatch(detailsProduct(productId));
  }, [dispatch, productId])

  const addToCartHandler = () => {
  setCheckClick(true)
  let size = mySize || product.size[0];
  dispatch(addToCart(productId, size))
  }
  const handlerBack = () => {
    window.history.go(-1);
  }

  const recomendation = useMemo(() => {
    if (product?.recomendationProduct) {
      const recomedProducts = [...product.recomendationProduct]
        ?.map((id) => products?.find(({_id, size}) => _id === id && !!size.length))
        ?.filter(Boolean);
      return recomedProducts.length > 0 ? recomedProducts : null;
    }
    return null;
  }, [product?.recomendationProduct, products])

  return (
        loading ? <LoadingBox/> 
      :
      error ? <MessageBox>{error}<br/> Повторите попытку позже</MessageBox>
    : (
      <div className="product">
        <Link className="product-link"><button onClick={handlerBack}>Назад</button></Link>
       
    <div className="row row-top">
        <div className="product-image">
          <img src={product.src} alt={product.name}/>
          <div className="zoom" onClick={() => {
            setShowImage(!showImage)
          }} ><img src={window.location.origin + '/img/icons/zoom-in.png'} alt="zoom"/></div>
        </div>
        <div className="product-cart">
    <ul className="product-desc">
      <li>{product.name.toUpperCase() + ' ' + product.article}</li>
      <li>Цвет: {product.color}</li>
      { product.oldPrice ? <li><del>{product.oldPrice} рублей</del></li> :  null}
      <li className="new-price">{product.newPrice} рублей</li>
      <li>Состав: {product.composition}</li>
      <li>{`Выбрать размер: `}
        <select value={mySize} onChange={(e) => {
          setMySize(e.target.value)}}
         className="product-size">
          {product.size.sort((a,b) => a-b).map(size =>(<option key={size} value={size}>{size}</option>))}
        </select>
      </li>
    </ul>
    {checkClick ? (<Link className="cart-call" to="/cart">Перейти в корзину</Link>) : null}

    <button onClick={addToCartHandler} className="product-button">Корзина</button>
        </div>
      </div>
     <div className={showImage ? "show-image" : "show-image hide-image"} onClick={(e) => {
            if(e.target.classList == "show-image") setShowImage(!showImage)
          }}>
     <div className="product-image show-img-block">
          <img src={product.src} alt={product.name}/>
          <div className="zoom" onClick={() => {
            setShowImage(!showImage)
          }} ><img src={window.location.origin + '/img/icons/zoom-out.png'} alt="zoom"/></div>
        </div>
     </div>
     {recomendation
       ? (
        <>
          <h3>Рекомендуем:</h3>
          <div className='cart-wrapper-recomendation'>
          {recomendation.map(({_id: id, src, name, article, newPrice }) => (
            <Link to={`/product/${id}`} key={id}>
              <img className="middle-img" src={src} alt={name}/>
              <p className='middle-paragraph-recomendation'>{name} {article}</p>
              <p className='middle-paragraph-recomendation'>{ newPrice } руб.</p>
            </Link>
          ))}
          </div>
        </>
        ) : null
      }
      </div>)
  )
}
