import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { CART_EMPTY } from '../constans/cartConstans';
import {detailsOrder} from '../actions/orderActions';

export default function OrderScreen(props) {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const orderId = props.match.params.id;
  
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  useEffect(() => {
   dispatch(detailsOrder(orderId))
    if(!userInfo) {
      props.history.push('/signin');
      dispatch({ type: CART_EMPTY});
    }
  }, [dispatch, orderId, props.history, userInfo]);

  return loading ? (
      <LoadingBox></LoadingBox>
    ) : error ? (
      <MessageBox>{error}<br/> Повторите попытку позже</MessageBox>
    ) : 
   (<div className="history">
      <h2>Заказ № {order._id.substring(19)} от {order.createdAt.substring(0,10).split('-').reverse().join('-')} года</h2>
      <p className="order-desc">Ваш заказ успешно оформлен</p>
      <p><strong>Для подтверждения заказа с вами свяжется менеджер или поступит смс о готовности заказа</strong></p>
      <p>Товар резервируется на 24 часа с момента подтверждения заказа</p>
      <br/>
      <h3><strong>Общая сумма заказа: <span>{order.totalPrice}</span>{' '} рублей</strong></h3>
        { userInfo ? (
        <div>
        <h3>Данные покупателя:</h3>
        <p>{userInfo.name}</p>
      <p>{userInfo.phone}</p>
      </div>) : null}
      <h3>Позиции в заказе:</h3>
     { order.orderItems.map(orderItem => {
            return (<div className="cart-pickup-wrapper" key={orderItem.product}>
               <Link to={`/product/${orderItem.product}`}><img className="mini-img" src={orderItem.imageSrc} alt={orderItem.name}/></Link>
              <ul className="cart-pickup-desc">
                <Link to={`/product/${orderItem.product}`}><li>{orderItem.name} {orderItem.article}</li></Link>
                <li>Цвет: {orderItem.color}</li>
                <li>Размер: {orderItem.size}</li>
              </ul>
              <p className="cart-pickup-price">{orderItem.price} рублей</p>
               </div>)
               })}
    </div>) 
}
