import { PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS,
PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_FILTER_LIST_REQUEST, PRODUCT_FILTER_LIST_SUCCESS, PRODUCT_FILTER_LIST_FAIL, PRODUCT_FILTER_LIST_REMOVE, PRODUCT_ADD_LIST_REQUEST, PRODUCT_ADD_LIST_SUCCESS, PRODUCT_ADD_LIST_FAIL, PRODUCT_UPDATE_LIST_REQUEST, PRODUCT_UPDATE_LIST_FAIL, PRODUCT_UPDATE_LIST_SUCCESS, PRODUCT_DELETE_LIST_REQUEST, PRODUCT_DELETE_LIST_SUCCESS, PRODUCT_DELETE_LIST_FAIL, PRODUCT_DELETE_ALL_LIST_REQUEST, PRODUCT_DELETE_ALL_LIST_SUCCESS, PRODUCT_DELETE_ALL_LIST_FAIL } from "../constans/productConstans"

export const productListReducer = (state = {loading: true, products: []}, action) => {
  switch(action.type) {
    case PRODUCT_LIST_REQUEST:
    return {loading: true};
    case PRODUCT_LIST_SUCCESS:
    return {loading: false, products: action.payload};
    case PRODUCT_LIST_FAIL:
    return {loading: false, error: action.payload}
    default:
    return state
  }
}
export const productDetailsReducer = (state = {product: {}, loading: true}, action) => {
   switch(action.type) {
    case PRODUCT_DETAILS_REQUEST:
    return {loading: true};
    case PRODUCT_DETAILS_SUCCESS:
    return {loading: false, product: action.payload};
    case PRODUCT_DETAILS_FAIL:
    return {loading: false, error: action.payload}
    default:
    return state
  }
}
export const filterProductsReducer = (state = {loading: true}, action) => {
  switch(action.type) {
    case PRODUCT_FILTER_LIST_REQUEST:
    return {loading: true};
    case PRODUCT_FILTER_LIST_SUCCESS:
    return {loading: false, products: action.payload};
    case PRODUCT_FILTER_LIST_FAIL:
    return {loading: false, error: action.payload}
    case PRODUCT_FILTER_LIST_REMOVE:
      return { ...state, error: '', products: null };
    default:
    return state
}
}

export const productsAddBDReducer = (state = {loading: true}, action) => {

  switch (action.type) {
    case PRODUCT_ADD_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_ADD_LIST_SUCCESS:
      return { loading: false, createProduct: action.payload };
    case PRODUCT_ADD_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const productsUpdateBDReducer = (state = {}, action) => {

  switch (action.type) {
    case PRODUCT_UPDATE_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_LIST_SUCCESS:
      return { loading: false, success: true};
    case PRODUCT_UPDATE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const productsDeleteSizeBDReducer = (state = {}, action) => {

  switch (action.type) {
    case PRODUCT_DELETE_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_LIST_SUCCESS:
      return { loading: false, success: true};
    case PRODUCT_DELETE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


export const productsRemoveBDReducer = (state = {}, action) => {

  switch (action.type) {
    case PRODUCT_DELETE_ALL_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_ALL_LIST_SUCCESS:
      return { loading: false, success: true};
    case PRODUCT_DELETE_ALL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}