import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      arrows: false,
      className: "slide",
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      pauseOnDotsHover: true,
      autoplaySpeed: 3000,
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <Link to="/new"><img src="./img/slider/new.jpg" alt="new"/></Link>
          </div>
          <div>
            <Link to="/product/66a4d31555f07bee80704692"><img src="./img/slider/costume.jpg" alt="costume"/></Link>
          </div>
          <div>
            <Link to="/shirt"><img src="./img/slider/shirts.jpg" alt="shirts"/></Link>
          </div>
          <div>
            <img src="./img/slider/actual.jpg" alt="actual"/>
          </div>
        </Slider>
      </div>
    );
  }
}