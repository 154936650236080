import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import {createOrder} from '../actions/orderActions';
import { ORDER_CREATE_RESET } from '../constans/orderConstans';
import { CART_EMPTY } from '../constans/cartConstans';

export default function PlaceOrderScreen(props) {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const orderCreate = useSelector((state) => state.orderCreate);
  const { loading, success, error, order } = orderCreate;
  cart.totalPrice = cart.cartItems.reduce((a,c) => a+c.price, 0);
  

  
  const placeOrderhandler = () => {
      dispatch(createOrder({...cart, orderItems: cart.cartItems}))
  }
  useEffect(() => {
    if (success) {
      props.history.push(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
    if(!userInfo) {
      props.history.push('/signin');
      dispatch({ type: CART_EMPTY});
    }
  }, [dispatch, order, props.history, success, userInfo]);

  return (
    <div className="placeorder">
        { userInfo ? (
        <div>
        <h2>Данные покупателя:</h2>
        <p><strong>Имя и фамилия:<sup className="large">*</sup></strong> {userInfo.name}</p>
      <p><strong>Номер телефона:<sup className="large">*</sup></strong> {userInfo.phone}</p>
      <p><sup className="large">Изменить информацию о себе можно в разделе "Профиль"</sup></p>
      </div>) : null}
      <h2>Вы заказали следующие товары:</h2>
     { cart.cartItems.map(cartItem => {
            return (<div className="cart-pickup-wrapper" key={cartItem.product}>
               <Link to={`/product/${cartItem.product}`}><img className="mini-img" src={cartItem.imageSrc} alt={cartItem.name}/></Link>
              <ul className="cart-pickup-desc">
                <Link to={`/product/${cartItem.product}`}><li>{cartItem.name} {cartItem.article}</li></Link>
                <li>Цвет: {cartItem.color}</li>
                <li>Размер: {cartItem.size}</li>
              </ul>
              <p className="cart-pickup-price">{cartItem.price} рублей</p>
               </div>)
               })}
        <p className="total"><strong>Общая сумма заказа: <span>{cart.totalPrice}</span>{' '} рублей</strong></p>
      <button className="button button-order" onClick={placeOrderhandler} disabled={cart.cartItems.length < 1}>Отправить заказ</button>
      {loading && <LoadingBox></LoadingBox>}
       {error && <MessageBox>{error} <br/> Повторите попытку позже</MessageBox>}
    </div>
  )
}
