import React from 'react';
import SimpleSlider  from '../components/Slider';
import Company from '../components/Company';

export default function HomeScreen() {
  
  return (<div>
    <SimpleSlider/>
      <div className="row center">
          <Company></Company>
    </div>
  </div>
)

}
