import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Header from './components/Header';
import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/СartScreen';
import Footer from './components/Footer';
import SigninScreen from './screens/SigninScreen';
import RegisterScreen from './screens/RegisterScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrderHistoryScreen from './screens/OrderHistotyScreen';
import ProfileScreen from './screens/ProfileScreen';
import CatalogScreen from './screens/CatalogScreen';
import ProductList from './screens/adminscreens/ProductList';
import DashBoard from './screens/adminscreens/DashBoard';
import NewScreen from './screens/catalogscreens/NewScreen';
import DressScreen from './screens/catalogscreens/DressScreen';
import SkirtScreen from './screens/catalogscreens/SkirtScreen';
import PantsJumpScreen from './screens/catalogscreens/PantsJumpScreen';
import JacketVestScreen from './screens/catalogscreens/JacketVestScreen';
import CoatCloakScreen from './screens/catalogscreens/CoatCloakScreen';
import BlouseScreen from './screens/catalogscreens/BlouseScreen';
import AcceScreen from './screens/catalogscreens/AcceScreen';
import ListOrder from './screens/adminscreens/ListOrder';
import ListUser from './screens/adminscreens/ListUser';
import Policy from './components/Policy';
import Cookie from './components/Cookie';
import AdminRoute from './components/AdminRoute';
import ForgetPassword from './screens/ForgetPassword';
import ScrollToTop from './components/ScrollToTop';
import ButtonScrollToTop from './components/ButtonScrollToTop';
//import { Delivery } from './components/Delivery';
import { useDispatch } from 'react-redux';
import { listProducts } from './actions/productActions';
import { ShirtScreen } from './screens/catalogscreens/ShirtScreen';

function App() {
  const dispatch = useDispatch();
  let [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(listProducts());
 }, [dispatch])

  return (
    <Router>
      <h1>Bizzarro</h1>
     <div className={show ? "grid-container overflow" : "grid-container"}>
       <Header show={show} setShow={setShow}/>
       <ButtonScrollToTop/>
      <main className={show ? "blur" : null} onClick={() => {
       setShow(false)
     }}>
        <ScrollToTop/>
        <Route path="/" component={HomeScreen} exact></Route>
        <Route path="/catalog" component={CatalogScreen}></Route>
        <Route path="/cart/:id?" component={CartScreen}></Route>
        <Route path="/product/:id" component={ProductScreen}></Route>
        <Route path="/register" component={RegisterScreen}></Route>
        <Route path="/signin" component={SigninScreen}></Route>
        <Route path="/placeorder" component={PlaceOrderScreen}></Route>
        <Route path="/order/:id" component={OrderScreen}></Route>
        <Route path="/orderhistory" component={OrderHistoryScreen}></Route>
        <Route path="/profile" component={ProfileScreen}></Route>
        <AdminRoute path="/productList" component={ProductList}></AdminRoute>
        <AdminRoute path="/dashboard" component={DashBoard}></AdminRoute>
        <Route path="/new" component={NewScreen}></Route>
        <Route path="/dress" component={DressScreen}></Route>
        <Route path="/skirts" component={SkirtScreen}></Route>
        <Route path="/pants&jumpsuits" component={PantsJumpScreen}></Route>
        <Route path="/jackets&vests" component={JacketVestScreen}></Route>
        <Route path="/coats&cloaks" component={CoatCloakScreen}></Route>
        <Route path="/blouse" component={BlouseScreen}></Route>
        <Route path="/accessory" component={AcceScreen}></Route>
        <AdminRoute path="/orderlist" component={ListOrder}></AdminRoute>
        <AdminRoute path="/userlist" component={ListUser}></AdminRoute>
        <Route path="/forgetpass" component={ForgetPassword}></Route>
        <Route path="/policy" component={Policy}></Route>
        <Route path="/cookie" component={Cookie}></Route>
        <Route path="/shirt" component={ShirtScreen}/>
      </main>
      <div className="border"></div>
    <Footer show={show} setShow={setShow}/>
  </div>
  </Router>
  );
}

export default App;
