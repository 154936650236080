import {compose, applyMiddleware, createStore, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import { cartReducer } from './reducers/cartReducers';
import { confirmOrderBDReducer, orderCancelBDReducer, orderCreateReducer, orderDetailsReducer, orderHistoryListReducer, orderMineListReducer, orderRemoveBDReducer,} from './reducers/orderReducers';
import {filterProductsReducer, productDetailsReducer, productListReducer, productsAddBDReducer, productsDeleteSizeBDReducer, productsRemoveBDReducer, productsUpdateBDReducer} from './reducers/productReducers';
import { userRegisterReducer, userSigninReducer, userDetailsReducer, userUpdateProfileReducer, listUserReducer, userRemoveDBReducer, userSendPhoneReducer, userUpdatePasswordReducer,} from './reducers/signinReducer';

const reducer = combineReducers({
  productList: productListReducer,
  productAdd: productsAddBDReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userList: listUserReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderMineList: orderMineListReducer,
  orderHistoryList: orderHistoryListReducer,
  orderRemove: orderRemoveBDReducer,
  orderCancel: orderCancelBDReducer,
  orderConfirm: confirmOrderBDReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userRemove: userRemoveDBReducer,
  codeSendPhone: userSendPhoneReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  filterProducts: filterProductsReducer,
  productUpdate: productsUpdateBDReducer,
  productDeleteSize: productsDeleteSizeBDReducer,
  productDelete: productsRemoveBDReducer
})

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null,
  },
  cart: {
    cartItems: [],
  }
};

let store;

if(process.env.NODE_ENV === 'development') {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)))
} else {
  store = createStore(reducer, initialState, applyMiddleware(thunk))
}

export default store;
