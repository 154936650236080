import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listUser, userRemoveDB } from '../../actions/signinActions';
import MessageBox from '../../components/MessageBox';
import LoadingBox from '../../components/LoadingBox';

export default function ListUser() {
    const dispatch = useDispatch();
    const userList = useSelector(state => state.userList);
    const {loading, error, users} = userList;

    useEffect(() => {
   dispatch(listUser())
    }, [dispatch])

    const handlerRemoveUser = (id) => {
        dispatch(userRemoveDB(id));
        dispatch(listUser())
    }

    return (
        <div className="history">
        <h3>Пользователи</h3>
        {loading ? (<LoadingBox></LoadingBox>) : error ? (<MessageBox>{error}</MessageBox>) : 
        (<table className="table">
        <thead>
        <tr>
         <th>Id пользователя</th>
        <th>Имя пользователя</th>
        <th>Номер телефона</th>
        <th></th>
        </tr>
        </thead>
        <tbody>
        {users ? users.map((user) => (
        <tr key={user._id}>
            <td>{user._id}</td>
            <td>{user.name}</td>
            <td>{user.phone}</td>
            <td><button onClick={e => {
               handlerRemoveUser(user._id)}} className="btn-filter-submit">Удалить</button></td>
        </tr>
        )) : <p>Пользователи не найдены</p>}
        </tbody>
        </table>)}
            </div>
    )
}
