import React from 'react';
import {Link} from 'react-router-dom';

export default function Product(props) {
  const {product}= props;
  
  const setScroll = () => {
   localStorage.setItem('scroll', JSON.stringify(window.scrollY))
  }
  
    return product.size.length > 0 
    ? 
     (
      <div key={product._id} className="card">
       <Link onClick={e => {
         setScroll()
       }} to={`/product/${product._id}`}>
        <div className="wrapper-img">
           <img loading="lazy" className="medium" src={product.src} alt={product.name} />
           {/*product.article.match(/S/g) && <p class="action-new">AUTUMN-WINTER COLLECTION 22-23</p>*/}
           </div>
       </Link>
       <div className="card-body">
         <Link onClick={e => {
         setScroll()
       }} to={`/product/${product._id}`}>
           <h2>{product.name} <span className="article">{product.article}</span> Цвет:<span className="color">{product.color}</span></h2>
         </Link>
         { product.oldPrice ? <s className="old-price"><span>{product.oldPrice}</span>рублей</s> :  <br/>}
         <div className="new-price"><span>{product.newPrice}</span>рублей</div>
       </div>
       <Link onClick={e => {
         setScroll()
       }} className="button" to={`/product/${product._id}`}>Посмотреть</Link>
     </div>
     ) : null
}
