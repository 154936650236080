import axios from "axios";
import {CART_ADD_ITEM, CART_REMOVE_ITEM} from '../constans/cartConstans';

export const addToBasket = (productId, size, color) => async (dispatch, getState) => {
   
  const {data} = await axios.get(`/api/products/${productId}`);
  const { size: sizes } = data;
  const isSize = sizes.find((dataSize) => dataSize === size);
  const isColor = color === data.color;
  if (data && isSize && isColor) {
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
         name: data.name,
         imageSrc: data.src,
         color: data.color,
         article: data.article,
         price: data.newPrice,
         product: data._id,
         size,
       },
    });
    localStorage.setItem(
       'cartItems',
       JSON.stringify(getState().cart.cartItems)
     );
  }
  return;
}

export const addToCart = (productId, size) => async (dispatch, getState) => {
   
   const {data} = await axios.get(`/api/products/${productId}`);
   dispatch({
     type: CART_ADD_ITEM,
     payload: {
        name: data.name,
        imageSrc: data.src,
        color: data.color,
        article: data.article,
        price: data.newPrice,
        product: data._id,
        size,
      },
   });
   localStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cart.cartItems)
    );
}

export const removeFromCart = (cartItem) => (dispatch, getState) => {
   dispatch({
     type: CART_REMOVE_ITEM,
     payload: {cartItem},
   });
    localStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cart.cartItems)
    );
}