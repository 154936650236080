import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import InputMask from 'react-input-mask';
import { sendPhone, updatePassword } from '../actions/signinActions';

export default function ForgetPassword(props) {
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const codeSendPhone = useSelector(state => state.codeSendPhone);
    const {newcode, error: err} = codeSendPhone;
    const [click, setClick] = useState(false);
    
    const redirect = props.location.search ? props.location.search.split('=')[1] : '/';
  
    const userUpdatePassword = useSelector((state) => state.userUpdatePassword);
    const { userInfo, loading} = userUpdatePassword;
    
    const dispatch = useDispatch();
  
    const handlerSendPhone = (phone) => {
        dispatch(sendPhone(phone)); 
    }

    const handlerSendCode = (code) => {
        if(code == newcode.random) {
          dispatch(updatePassword(phone, code))
        } else alert('Неверный код доступа')
    }

    useEffect(() => {
      if (userInfo) {
        props.history.push(redirect);
      }
    }, [props.history, redirect, userInfo, err]);

    return (
      <div>
        <form className="form" onSubmit={e => {
            e.preventDefault();
           handlerSendPhone(phone);
           setClick(!click)
        }}>
          <div>
            <h2>Забыли пароль?</h2>
          </div>
          {loading && <LoadingBox></LoadingBox>}
          {err && <MessageBox>{err}</MessageBox>}
          {click && newcode?.random && !err && <div style={{color: "#ec6928"}}>Скоро вам поступит смс с новым паролем. Пожалуйста, не обновляйте страницу</div>}
          <div>
            <label htmlFor="phone">Телефон</label>
            <InputMask placeholder="+7 (999) 999-99-99" mask="+7 (999) 999-99-99" maskChar=" "  id="phone"
            pattern="\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}" type="tel"
            required  min-length="18" onChange={(e) => setPhone(e.target.value)}/>
          </div>
          <div>
            <label />
            {click && !err ? (<button disabled className="button disabled" type="submit">
              Отправлено
            </button>) : (<button className="button" type="submit">
              Отправить код доступа
            </button>)}
          </div>
        </form>
        <form className="form" onSubmit={e => {
            e.preventDefault();
            handlerSendCode(code);
        }}>
        <div>
            <label htmlFor="code">Введите код из смс</label>
           <input value={code} id="code" onChange={(e) => setCode(e.target.value)}/>
          </div>
          <div>
            <label />
            {newcode?.random ? (<button className="button" type="submit">
              Войти
            </button>) : (<button disabled className="button disabled" type="submit">
              Войти
            </button>)}
          </div>
          <div>
            <label />
            <div>
              Уже есть аккаунт?{' '}
              <Link to={`/signin?redirect=${redirect}`}>Войти</Link>
            </div>
          </div>
          <div>
          <label />
          <div>
            Новый пользователь?{' '}
            <Link to={`/register?redirect=${redirect}`}>
              Зарегистрироваться
            </Link>
          </div>
        </div>
        </form> 
      </div>
    )
}
