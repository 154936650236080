export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST',
  PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS',
  PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST',
  PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS',
  PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';

  export const PRODUCT_FILTER_LIST_REQUEST = 'PRODUCT_FILTER_LIST_REQUEST',
  PRODUCT_FILTER_LIST_SUCCESS = 'PRODUCT_FILTER_LIST_SUCCESS',
  PRODUCT_FILTER_LIST_FAIL = 'PRODUCT_FILTER_LIST_FAIL',
  PRODUCT_FILTER_LIST_REMOVE = 'PRODUCT_FILTER_LIST_REMOVE';

  export const PRODUCT_ADD_LIST_REQUEST = 'PRODUCT_ADD_LIST_REQUEST',
  PRODUCT_ADD_LIST_SUCCESS = 'PRODUCT_ADD_LIST_SUCCESS',
  PRODUCT_ADD_LIST_FAIL = 'PRODUCT_ADD_LIST_FAIL',
  PRODUCT_ADD_LIST_REMOVE = 'PRODUCT_ADD_LIST_REMOVE';

  export const PRODUCT_UPDATE_LIST_REQUEST = 'PRODUCT_UPDATE_LIST_REQUEST',
  PRODUCT_UPDATE_LIST_SUCCESS = 'PRODUCT_UPDATE_LIST_SUCCESS',
  PRODUCT_UPDATE_LIST_FAIL = 'PRODUCT_UPDATE_LIST_FAIL';

  export const PRODUCT_DELETE_LIST_REQUEST = 'PRODUCT_DELETE_LIST_REQUEST',
  PRODUCT_DELETE_LIST_SUCCESS = 'PRODUCT_DELETE_LIST_SUCCESS',
  PRODUCT_DELETE_LIST_FAIL = 'PRODUCT_DELETE_LIST_FAIL';

  export const PRODUCT_DELETE_ALL_LIST_REQUEST = 'PRODUCT_DELETE_ALL_LIST_REQUEST',
  PRODUCT_DELETE_ALL_LIST_SUCCESS = 'PRODUCT_DELETE_ALL_LIST_SUCCESS',
  PRODUCT_DELETE_ALL_LIST_FAIL = 'PRODUCT_DELETE_ALL_LIST_FAIL';

  export const optionsSategory = [
    {key: 'none', value: '', name: 'Выбрать категорию'},
    {key: 'аксессуар', value: 'аксессуар', name: 'аксессуар'},
    {key: 'блузка', value: 'блузка', name: 'блузка'},
    {key: 'джемпер', value: 'джемпер', name: 'джемпер'},
    {key: 'пальто', value: 'пальто', name: 'пальто'},
    {key: 'плащ', value: 'плащ', name: 'плащ'},
    {key: 'куртка', value: 'куртка', name: 'куртка'},
    {key: 'платье', value: 'платье', name: 'платье'},
    {key: 'жакет', value: 'жакет', name: 'жакет'},
    {key: 'жилет', value: 'жилет', name: 'жилет'},
    {key: 'брюки', value: 'брюки', name: 'брюки'},
    {key: 'комбинезон', value: 'комбинезон', name: 'комбинезон'},
    {key: 'юбка', value: 'юбка', name: 'юбка'},
    {key: 'футболка', value: 'футболка', name: 'футболка'},
  ]