import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts, productsAddBD, productsRemoveSizeBD, productsUpdateBD } from '../../actions/productActions';
import MessageBox from '../../components/MessageBox';
import { optionsSategory } from '../../constans/productConstans';

export default function ProductList() {
    const dispatch = useDispatch();
    const [category, setCategory] = useState({
      createCategory: "",
      updateCategory: "",
    });
    const [src, setSrc] = useState({
      createSrc: "",
      updateSrc: "",
    });
    const [name, setName] = useState({
      createName: "",
      updateName: "",
    });
    const [article, setArticle] = useState({
         createArticle: "",
         updateArticle: "",
         deleteArticle: ""
    });
    const [color, setColor] = useState({
      createColor: "",
      updateColor: "",
      deleteColor: ""
 });
    const [oldPrice, setOldPrice] = useState({
      createOldPrice: "",
      updateOldPrice: ""
    });
    const [newPrice, setNewPrice] = useState({
      createNewPrice: "",
      updateNewPrice: ""
    });
    const [composition, setComposition] = useState({
      createComposition: "",
      updateComposition: ""
    });
    const [size, setSize] = useState({
      createSize: "",
      updateSize: "",
      deleteSize: ""
 });
    const [recomendationProduct, setRecomendationProduct] = useState({
      createRecomendation: [],
      updateRecomendation: [],
    });
    const [filter, setFilter] = useState({
      createFilter: "",
      updateFilter: "",
    });
    const { products } = useSelector(state => state.productList);
    const {createProduct, error: error_add} = useSelector(state => state.productAdd);
    const { success, error: error_update}  = useSelector(state => state.productUpdate);
    const { success: success_delete, error: error_delete } = useSelector(state => state.productDeleteSize);

    const filterSizeProducts = useMemo(() => {
      return [...(products || [])]?.filter(({ size }) => !!size.length);
    }, [products]);

    // Отфильтрованный массив для рекомендаций
   const filteredProducts = (valueFilter) => {
      const pattern = new RegExp(valueFilter, "i", "g", "m");
      return valueFilter ? [...filterSizeProducts?.filter((product) => 
         product?.article.match(pattern) || product?.category.match(pattern))] : filterSizeProducts;
   }
   // Очищаем поля после отправки формы
   const resetFields = (paramAction) => {
      if (!["delete"].includes(paramAction)) {
      setCategory((state) => ({...state, [`${paramAction}Category`]: ""}));
      setSrc((state) => ({...state, [`${paramAction}Src`]: ""}));
      setName((state) => ({...state, [`${paramAction}Name`]: ""}));
      setOldPrice((state) => ({...state, [`${paramAction}OldPrice`]: ""}));
      setNewPrice((state) => ({...state, [`${paramAction}NewPrice`]: ""}));
      setComposition((state) => ({...state, [`${paramAction}Composition`]: ""}));
      setRecomendationProduct((state) => ({...state, [`${paramAction}Recomendation`]: []}));
      setFilter((state) => ({...state, [`${paramAction}Filter`]: ""}));
      }
      setSize((state) => ({...state, [`${paramAction}Size`]: ""}));
      setArticle((state) => ({...state, [`${paramAction}Article`]: ""}));
      setColor((state) => ({...state, [`${paramAction}Color`]: ""}));
   }
   // Хэндлер на отправку формы по добавлению и обновлению товара
   const handlerCreateUpdateProduct = (paramAction) => {
      const isCreateParamAction = paramAction === "create";
      const fieldsObject = {
         category: category?.[`${paramAction}Category`],
         src: src?.[`${paramAction}Src`], 
         name: name?.[`${paramAction}Name`], 
         article: article?.[`${paramAction}Article`], 
         color:  color?.[`${paramAction}Color`], 
         oldPrice: oldPrice?.[`${paramAction}OldPrice`], 
         newPrice: newPrice?.[`${paramAction}NewPrice`], 
         composition: composition?.[`${paramAction}Composition`], 
         size: size?.[`${paramAction}Size`], 
         recomendationProduct: recomendationProduct?.[`${paramAction}Recomendation`]
      }
      dispatch(isCreateParamAction ? productsAddBD(fieldsObject) : productsUpdateBD(fieldsObject));
      resetFields(paramAction);
      dispatch(listProducts());
     }
     // Хэндлер на удаление товара
     const handlerRemoveSize = () => {
        dispatch(productsRemoveSizeBD({article: article.deleteArticle, color: color.deleteColor, size: size.deleteSize}));
        resetFields("delete");
        dispatch(listProducts());
     }
     // Хэндлер для добавления рекомендаций
     const handlerRecomendation = (value, paramAction) => {
       setRecomendationProduct((state) => {
         return !state?.[`${paramAction}Recomendation`].includes(value) 
           ? {...state, [`${paramAction}Recomendation`] : [...state[`${paramAction}Recomendation`], value]}
           : {...state, [`${paramAction}Recomendation`] : state[`${paramAction}Recomendation`].filter((product) => product !== value)};
       })
     }

     const handlerSubmitForm = (event, paramAction) => {
      event.preventDefault();
      handlerCreateUpdateProduct(paramAction)
     }
     // Хэндлер на изменения стейта
     const handleChangeFormField = (set, paramActionField, value) => {
      set((state) => ({...state, [`${paramActionField}`]: value}))
     }

    return (
      <div className="row center">
       <h2>Добавление товара</h2>
       <div className="admin-list">
         <form onSubmit={e => handlerSubmitForm(e, "create")} className="admin-form">
           <div>
             <select className="category-admin" value={category.createCategory} onChange={(e) => {
                handleChangeFormField(setCategory, "createCategory", e.target.value)
                setRecomendationProduct((state) => ({...state, ["createRecomendation"]: []}));
             }}>
               {optionsSategory.map(({key, value, name}) => (
                  <option key={key} value={value}>{name}</option>
               ))}
            </select>
           </div>
           <div>
            <label htmlFor="photo-src">Загрузите изображение { }</label>
            <input type="file" id="photo-src" onChange={(e) => {
               const fileObj = e.target?.files?.[0];
               if (!fileObj) {
                 return;
               }
               handleChangeFormField(setSrc, "createSrc", `https://bizzarro74.ru/img/catalog/${fileObj?.name}`)}}/>       
           </div>
           <div>
            <label htmlFor="name">Название{ }</label>
            <input type="text" id="name" value={name.createName} onChange={(e) => handleChangeFormField(setName, "createName", e.target.value)}/>       
           </div>
           <div>
            <label htmlFor="article">Артикул (английская раскладка для Cuvee) { }</label>
            <input type="text" id="article" value={article.createArticle} onChange={(e) => handleChangeFormField(setArticle, "createArticle", e.target.value)}/>       
           </div>
           <div>
            <label htmlFor="color">Цвет { }</label>
            <input type="text" id="color" value={color.createColor} onChange={(e) => handleChangeFormField(setColor, "createColor", (e.target.value).toLowerCase())}/>       
           </div>
           <div>
            <label htmlFor="oldPrice">Начальная цена { }</label>
            <input type="text" id="oldPrice" value={oldPrice.createOldPrice} onChange={(e) => handleChangeFormField(setOldPrice, "createOldPrice", e.target.value)}/>       
           </div>
           <div>
            <label htmlFor="newPrice">Окончательная цена { }</label>
            <input type="text" id="newPrice" value={newPrice["createNewPrice"]} onChange={(e) => handleChangeFormField(setNewPrice, "createNewPrice", e.target.value)}/>       
           </div>
           <div>
            <label htmlFor="composition">Состав { }</label>
            <input type="text" id="composition"  value={composition.createComposition} onChange={(e) => handleChangeFormField(setComposition, "createComposition", (e.target.value).toLowerCase())}/>       
           </div>
           <div>
            <label htmlFor="size">Pазмерный ряд (через запятую) { }</label>
            <input type="text" id="size"  value={size["createSize"]} onChange={(e) => handleChangeFormField(setSize, "createSize", e.target.value)}/>       
           </div>
           <div style={{position: "relative"}}>
             <p>Выберите из списка рекомендуемые товары</p>
             <label htmlFor="filter">Введите артикул или категорию товара{ }</label>
             <input type="text" id="filter" placeholder='Поиск'  value={filter.createFilter} onChange={(e) => {
               handleChangeFormField(setFilter, "createFilter", e.target.value.replace(/[^a-zа-я0-9-]/gi, ''))
             }} />
              <select className="category-admin recomendation" value={recomendationProduct.createRecomendation}
                 onClick={(e) => handlerRecomendation(e.target.value, "create")} multiple>
                   {filteredProducts(filter.createFilter)?.map((product, index) => {
                     return (<option key={product?._id} value={product?._id}>{`${index + 1}. ${product?.category}_${product?.article}_${product?.color}`}</option>)
                 })}
              </select>

              {recomendationProduct?.createRecomendation.length ? (
                <div className='recomendationFilterList'>
                 <h4>Выбранные товары:</h4>
                 {recomendationProduct.createRecomendation.map((id) => (
                    <div key={id} className='recomendationFilter'>
                      {filterSizeProducts?.find(({_id}) => _id === id)?.article} 
                      <button onClick={() => handlerRecomendation(id, "create")}>Удалить</button>
                    </div>
                 ))}
                </div>
              ) : null
            }
           </div>  
          <button className="btn-filter-submit admin-btn" type="submit">Добавить</button>
       </form>
       {createProduct ? <MessageBox>Товар загружен</MessageBox> : <MessageBox>{error_add}</MessageBox>}
      </div>
         {/*Обновление товара */}
         <h2>Обновление товара</h2>
         <div className="admin-list">
            <form onSubmit={e => handlerSubmitForm(e, "update")} className="admin-form">
            <div>
               <select className="category-admin" value={category.updateCategory} onChange={(e) => {
               handleChangeFormField(setCategory, "updateCategory", e.target.value)
               setRecomendationProduct((state) => ({...state, ["updateRecomendation"]: []}));
            }}>
               {optionsSategory.map(({key, value, name}) => (
                  <option key={key} value={value}>{name}</option>
               ))}
               </select>
         </div>
         <div>
            <label htmlFor="photo-src">Загрузите изображение { }</label>
            <input type="file" id="photo-src" onChange={(e) => {
               const fileObj = e.target?.files?.[0];
               if (!fileObj) {
                 return;
               }
               handleChangeFormField(setSrc, "updateSrc", `../img/catalog/${fileObj?.name}`)
            }}/>       
         </div>
         <div>
            <label htmlFor="name">Название (с заглавной буквы){ }</label>
            <input type="text" id="name" value={name.updateName} onChange={(e) => handleChangeFormField(setName, "updateName", e.target.value)}/>       
         </div>
         <div>
            <label htmlFor="article"><strong>Артикул (обязательно к заполнению)</strong> { }</label>
            <input type="text" id="article" value={article.updateArticle} onChange={(e) => handleChangeFormField(setArticle, "updateArticle", e.target.value)}/>      
         </div>
         <div>
            <label htmlFor="color"><strong>Цвет (обязательно к заполнению)</strong> { }</label>
            <input required type="text" id="color" value={color.updateColor} onChange={(e) => handleChangeFormField(setColor, "updateColor", (e.target.value).toLowerCase())}/>       
         </div>
         <div>
            <label htmlFor="oldPrice">Начальная цена { }</label>
            <input type="text" id="oldPrice" value={oldPrice.updateOldPrice} onChange={(e) => handleChangeFormField(setOldPrice, "updateOldPrice", e.target.value)}/>      
         </div>
         <div>
            <label htmlFor="newPrice">Окончательная цена { }</label>
            <input type="text" id="newPrice" value={newPrice.updateNewPrice} onChange={(e) => handleChangeFormField(setNewPrice, "updateNewPrice", e.target.value)}/>       
         </div>
         <div>
            <label htmlFor="composition">Состав { }</label>
            <input type="text" id="composition"  value={composition.updateComposition} onChange={(e) => handleChangeFormField(setComposition, "updateComposition", (e.target.value).toLowerCase())}/>      
         </div>
         <div>
            <label htmlFor="size">Pазмерный ряд (через запятую) { }</label>
            <input type="text" id="size"  value={size["updateSize"]} onChange={(e) => handleChangeFormField(setSize, "updateSize", e.target.value)}/>        
         </div>
         <div style={{position: "relative"}}>
            <p>Выберите из списка рекомендуемые товары</p>
            <label htmlFor="filter">Введите артикул или категорию товара{ }</label>
            <input type="text" id="filter" placeholder='Поиск'  value={filter.updateFilter} onChange={(e) => {
               handleChangeFormField(setFilter, "updateFilter", e.target.value.replace(/[^a-zа-я0-9-]/gi, ''))
            }} />
            <select className="category-admin recomendation" value={recomendationProduct.updateRecomendation}
               onClick={(e) => handlerRecomendation(e.target.value, "update")} multiple>
                 {filteredProducts(filter.updateFilter)?.map((product, index) => {
                     return (<option key={product?._id} value={product?._id}>{`${index + 1}. ${product?.category}_${product?.article}_${product?.color}`}</option>)
                  })}
            </select>

            {recomendationProduct?.updateRecomendation.length ? (
              <div className='recomendationFilterList'>
               <h4>Выбранные товары:</h4>
               {recomendationProduct.updateRecomendation.map((id) => (
                  <div key={id} className='recomendationFilter'>
                  {filterSizeProducts?.find(({_id}) => _id === id)?.article} 
                  <button onClick={() => handlerRecomendation(id, "update")}>Удалить</button>
                  </div>
               ))}
            </div>
            ) : null
            }
         </div>  
         <button className="btn-filter-submit admin-btn" type="submit">Обновить информацию</button>
      </form>
      {success ? <MessageBox>Информация по товару обновлена</MessageBox> : <MessageBox>{error_update}</MessageBox>}</div>
      {/* Списание товара */}
      <h2>Списание товара по размеру</h2>
         <div className="admin-list">
            <form  className="admin-form" onSubmit={e => {
               e.preventDefault();
               handlerRemoveSize()}}>
         <div>
            <label htmlFor="article">Артикул (английская раскладка для Cuvee) { }</label>
            <input type="text" id="article" value={article.deleteArticle} onChange={(e) => handleChangeFormField(setArticle, "deleteArticle", e.target.value)}/>       
         </div>
         <div>
            <label htmlFor="color">Цвет { }</label>
            <input type="text" id="color" value={color.deleteColor} onChange={(e) => handleChangeFormField(setColor, "deleteColor", (e.target.value).toLowerCase())}/>       
         </div>
         <div>
            <label htmlFor="size">Pазмер { }</label>
            <input type="text" id="size"  value={size.deleteSize} onChange={(e) => handleChangeFormField(setSize, "deleteSize", e.target.value)}/>       
         </div>
         <button className="btn-filter-submit admin-btn" type="submit">Списать товар</button>
      </form>
      {success_delete ? <MessageBox>Товар списан</MessageBox> : <MessageBox>{error_delete}</MessageBox>}</div>
         </div>)
}
