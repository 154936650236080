import {CART_ADD_ITEM, CART_EMPTY, CART_REMOVE_ITEM} from '../constans/cartConstans';


export const cartReducer  = (state = {cartItems: []}, action) => {
  switch(action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x.product === item.product && x.size === item.size);
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === item.product && x.size === existItem.size ? item : x
          ),
        };
      } else {
        return { ...state, cartItems: [...state.cartItems, item] };
      }
      case CART_REMOVE_ITEM:
        let indexEl = null;
        const { cartItem } = action.payload;
        
        state.cartItems.forEach(({product, size, color, article}, index) => {
          if(product === cartItem?.product && size === cartItem?.size && color === cartItem?.color && article === cartItem?.article) {
            indexEl = index;
          }
        })

        let cart1 = state.cartItems.slice(0, indexEl);
        let cart2 = state.cartItems.slice(indexEl+1);

        return {...state, cartItems: [...cart1, ...cart2]}

        case CART_EMPTY:
          return { ...state, error: '', cartItems: [] };
    default:
      return state;
  }
}