import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser, updateUserProfile } from '../actions/signinActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { USER_UPDATE_PROFILE_RESET } from '../constans/signinConstans';
import InputMask from 'react-input-mask';

export default function ProfileScreen() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const dispatch = useDispatch();

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;

 useEffect(() => {
  if(!user) {
    dispatch({ type: USER_UPDATE_PROFILE_RESET });
    dispatch(detailsUser(userInfo._id));
  } else {
    setName(user.name);
    setPhone(user.phone)
  }
  }, [dispatch, userInfo._id, user]);
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Пароли не совпадают');
    } else {
      dispatch(updateUserProfile({userId: user._id, name, phone, password}));
    }
  };
  return (
    <div>
      <form className="form" onSubmit={submitHandler}>
        <div>
          <h2>Профиль</h2>
        </div> {loading ? (<LoadingBox></LoadingBox>) : error ? ( <MessageBox>{error}<br/> Повторите попытку позже</MessageBox>) : (
          <>
           {loadingUpdate && <LoadingBox></LoadingBox>}
            {errorUpdate && (<MessageBox>{errorUpdate}<br/> Повторите попытку позже</MessageBox>)}
            {successUpdate && (<p className="update-success"> Профиль успешно обновлен</p>)}
            <div>
              <label htmlFor="name">Имя и фамилия</label>
              <input
                id="name"
                type="text"
                placeholder="Введите имя и фамилию"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="phone">Телефон</label>
              <InputMask placeholder="+7 (999) 999-99-99" mask="+7 (999) 999-99-99" maskChar=" "  id="phone"
                        pattern="\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}" type="tel"
                        value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
            <div>
              <label htmlFor="password">Пароль</label>
              <input
                id="password"
                type="password"
                placeholder="Введите новый пароль"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label htmlFor="confirmPassword">Повторите пароль</label>
              <input
                id="confirmPassword"
                type="password"
                placeholder="Повторите пароль"
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></input>
            </div>
            <div>
              <label />
              <button className="button" type="submit">
                Отправить
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
