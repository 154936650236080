import axios from "axios";
import { USER_SIGNIN_FAIL, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNOUT,
 USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, 
 USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_DETAILS_FAIL,
 USER_UPDATE_PROFILE_FAIL, USER_UPDATE_PROFILE_REQUEST, USER_UPDATE_PROFILE_SUCCESS, USER_GET_REQUEST, USER_GET_SUCCESS, USER_GET_FAIL, USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL, USER_FORGET_REQUEST, USER_FORGET_FAIL, USER_FORGET_SUCCESS, USER_UPDATE_PASSWORD_REQUEST, USER_UPDATE_PASSWORD_FAIL, USER_UPDATE_PASSWORD_SUCCESS, } from "../constans/signinConstans";

export const register = (name, phone, password) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { phone, password } });
  try {
    const { data } = await axios.post('/api/users/register', {
      name,
      phone,
      password,
    });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signin = (phone, password) => async(dispatch) => {
  dispatch({
    type: USER_SIGNIN_REQUEST,
    payload: {phone, password}
  });
  try {
     const {data} = await axios.post('/api/users/signin', {phone, password});
     dispatch({type: USER_SIGNIN_SUCCESS, payload: data});
     localStorage.setItem("userInfo", JSON.stringify(data))
  } catch(err) {
    dispatch({type: USER_SIGNIN_FAIL, payload: err.response && err.response.data.message ? err.response.data.message 
    : err.message})
  }
}

export const signout = () => (dispatch) => {

  localStorage.removeItem("userInfo");
  localStorage.removeItem('cartItems');
  dispatch({type: USER_SIGNOUT});
  document.location.href = '/signin';
}

export const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.put(`/api/users/profile`, user, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};

export const listUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_GET_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`/api/users`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    });
    dispatch({ type: USER_GET_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_GET_FAIL, payload: message });
  }
};

export const userRemoveDB = (id) => async (dispatch) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: { id } });
  try {
    const { data } = await axios.patch('/api/users/delete_user', {id});
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const sendPhone = (phone) => async(dispatch) => {
  dispatch({type: USER_FORGET_REQUEST, payload: {phone}});
  try {
    const {data} = await axios.post('/api/users/forget_pass', {phone});
    dispatch({ type: USER_FORGET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_FORGET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}

export const updatePassword = (phone, code) => async(dispatch) => {
  dispatch({type: USER_UPDATE_PASSWORD_REQUEST, payload: {phone,code}});
  try {
    const {data} = await axios.patch('/api/users/update_pass', {phone, code});
    dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  }catch (error) {
    dispatch({
      type: USER_UPDATE_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}