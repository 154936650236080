export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL';

export const ORDER_MINE_LIST_REQUEST = 'ORDER_MINE_LIST_REQUEST';
export const ORDER_MINE_LIST_SUCCESS = 'ORDER_MINE_LIST_SUCCESS';
export const ORDER_MINE_LIST_FAIL = 'ORDER_MINE_LIST_FAIL';

export const ORDER_HISTORY_LIST_REQUEST = 'ORDER_HISTORY_LIST_REQUEST';
export const ORDER_HISTORY_LIST_SUCCESS = 'ORDER_HISTORY_LIST_SUCCESS';
export const ORDER_HISTORY_LIST_FAIL = 'ORDER_HISTORY_LIST_FAIL';

export const ORDER_DELETE_LIST_REQUEST = 'ORDER_DELETE_LIST_REQUEST';
export const ORDER_DELETE_LIST_SUCCESS = 'ORDER_DELETE_LIST_SUCCESS';
export const ORDER_DELETE_LIST_FAIL = 'ORDER_DELETE_LIST_FAIL';

export const ORDER_CANCEL_LIST_REQUEST = 'ORDER_CANCEL_LIST_REQUEST';
export const ORDER_CANCEL_LIST_SUCCESS = 'ORDER_CANCEL_LIST_SUCCESS';
export const ORDER_CANCEL_LIST_FAIL = 'ORDER_CANCEL_LIST_FAIL';

export const ORDER_CONFIRM_LIST_REQUEST = 'ORDER_CONFIRM_LIST_REQUEST';
export const ORDER_CONFIRM_LIST_SUCCESS = 'ORDER_CONFIRM_LIST_SUCCESS';
export const ORDER_CONFIRM_LIST_FAIL = 'ORDER_CONFIRM_LIST_FAIL';