import React from 'react'

export default function Company() {
    return (<div>
        <h2 className="title">О компании</h2>
<section className="desc-company">
<h4>Магазин женской одежды Bizzarro в Челябинске является официальным представителем российско-итальянского бренда Bizzarro и Cuvee.</h4>
<div>
    <h5>Три основных принципа нашего бренда:</h5>
    <ul>
        <li style={{textTransform: "initial"}}>• Премиальное качество</li>
        <li style={{textTransform: "initial"}}>• Лимитированные коллекции</li>
        <li style={{textTransform: "initial"}}>• Собственное производство</li>
    </ul>
</div>
<p>Для жительниц города Челябинска доступен самовывоз из нашего оффлайн-магазина. 
    Вы можете заранее оформить заказ, и мы подготовим его к нужному времени.</p>
<em style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>С любовью, команда Bizzarro</em>

</section>
</div>)
}
