import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts, productsRemoveBD } from '../../actions/productActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';

export default function DashBoard() {
    const dispatch = useDispatch();
    const productList = useSelector(state => state.productList);
    const {loading, error, products} = productList;
    let date = new Date();
    

     const handlerRemove = (id) => {
      dispatch(productsRemoveBD(id));
      dispatch(listProducts());
     }

    return loading ? <LoadingBox/> 
        :
        error ? <MessageBox>{error}</MessageBox>
      : (<div className="admin-list">
            <h2>Список товаров на {date.toString().substring(0, 16)} г.</h2>
            <table className="table">
        <thead>
         <tr>
            <th>N</th>
            <th>Идентификатор</th>
            <th>Категория товара</th>
            <th>Название</th>
            <th>Артикул</th>
            <th>Цвет</th>
            <th>Цена начальная</th>
            <th>Цена со скидкой</th>
            <th>Состав</th>
            <th>Размерный ряд</th>
            <th></th>
         </tr>
        </thead>
        <tbody>
     {products ? products.map((product, index) => (
            <tr key={product._id}>
            <td>{index+1}</td>
            <td>{product._id}</td>
            <td>{product.category}</td>
            <td>{product.name}</td>
            <td>{product.article}</td>
            <td>{product.color}</td>
            <td>{product.oldPrice}</td>
            <td>{product.newPrice}</td>
            <td>{product.composition}</td>
            <td>{product.size.join(',')}</td>
            <td><button onClick={e => {
               handlerRemove(product._id)}} className="btn-filter-submit">Удалить</button></td>
         </tr>
   )) : (<p>Товары не найдены</p>)}
 </tbody>
</table>
</div>)
}
