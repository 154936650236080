import React from 'react';
import { useDispatch } from 'react-redux';
import {Link} from 'react-router-dom';
import {removeFromCart} from '../actions/cartActions';
import MessageBox from '../components/MessageBox';
import { useCart } from '../hooks/useCart';

export default function CartScreen(props) {
  const dispatch = useDispatch();
  const {cartItems} = useCart();
  
  const removeFromCartHundler = (cartItem) => {
    dispatch(removeFromCart(cartItem))
  }

  const checkoutHandler = () => {
props.history.push('/signin?redirect=placeorder')
  }

  return (
    <div className="cart-wrapper">
      {cartItems.length > 0 ? (<h2>Ваш заказ</h2>) : null}
      <div className="cart-cover">
      <div className="cart-pickup">
       { cartItems.length > 0 ? cartItems.map(
         cartItem => {
           return (<div className="cart-pickup-wrapper" key={`${cartItem.product}_${cartItem.size}_${cartItem.color}`}>
              <Link to={`/product/${cartItem.product}`}><img className="mini-img" src={cartItem.imageSrc} alt={cartItem.name}/></Link>
              <ul className="cart-pickup-desc">
                <Link to={`/product/${cartItem.product}`}><li>{cartItem.name} {cartItem.article}</li></Link>
                <li>Цвет: {cartItem.color}</li>
                <li>Размер: {cartItem.size}</li>
              </ul>
              <p className="cart-pickup-price">{cartItem.price} рублей</p>
              <div className="cart-pickup-remove" title="удалить" type="button" onClick={() => removeFromCartHundler(cartItem)}>
                <span></span>
                </div>
               </div>)
               }
       ) : (<MessageBox>Вы не добавили товары к заказу. <Link to='/catalog'>Перейти в каталог</Link></MessageBox>)
       }
      </div>
       {cartItems.length > 0 ? (<div className="order-pickup">
        <p className="order-pickup-sum">Общая стоимость товаров в корзине:<br/>
         <span className="order-pickup-total">{cartItems.reduce((a,c) => a+c.price, 0)} рублей</span>
        </p>
        <button className="order-pickup-btn button" type="button" onClick={checkoutHandler}>Оформить заказ</button>
      </div>) : null}
      </div>
    </div>
  )
}
