import React from 'react';
import { Link } from 'react-router-dom';


export default function Cookie() {
    const handlerBack = () => {
        window.history.go(-1);
      }
    return (
        <div className="policy">
             <Link className="product-link"><button onClick={handlerBack}>Назад</button></Link>
            <h2>Политика использования файлов cookie для www.bizzarro74.ru</h2>

<p>Это Политика использования файлов cookie для сайта www.bizzarro74.ru, доступная по адресу www.bizzarro74.ru.</p>

<p><strong>Что такое файлы cookie</strong></p>

<p>Как это принято почти на всех профессиональных веб-сайтах, этот сайт использует файлы cookie, которые представляют собой крошечные файлы, которые загружаются на ваш компьютер для улучшения вашего опыта. На этой странице описывается, какую информацию они собирают, как мы ее используем и почему нам иногда необходимо хранить эти файлы cookie. Мы также расскажем, как вы можете предотвратить сохранение этих файлов cookie, однако это может привести к ухудшению или «поломке» определенных элементов функциональности сайтов.</p>

<p><strong>Как мы используем файлы cookie</strong></p>

<p>Мы используем файлы cookie по разным причинам, подробно описанным ниже. К сожалению, в большинстве случаев нет стандартных отраслевых опций для отключения файлов cookie без полного отключения функций и функций, которые они добавляют на этот сайт. Рекомендуется оставить все файлы cookie, если вы не уверены, нужны они вам или нет, в случае, если они используются для предоставления услуги, которую вы используете.</p>

<p><strong>Отключение файлов cookie</strong></p>

<p>Вы можете предотвратить установку файлов cookie, изменив настройки своего браузера (см. Справку своего браузера, чтобы узнать, как это сделать). Имейте в виду, что отключение файлов cookie повлияет на функциональность этого и многих других веб-сайтов, которые вы посещаете. Отключение файлов cookie обычно приводит к отключению определенных функций и возможностей этого сайта. Поэтому рекомендуется не отключать файлы cookie. </p>
<p><strong>Файлы cookie, которые мы устанавливаем</strong></p>

<ul>

<li>
    <p>Файлы cookie, связанные с аккаунтом</p>
    <p>Если вы создадите учетную запись у нас, мы будем использовать файлы cookie для управления процессом регистрации и общего администрирования. Эти файлы cookie обычно удаляются при выходе из системы, однако 
        в некоторых случаях они могут оставаться впоследствии, чтобы запомнить настройки вашего сайта при выходе из системы.</p>
</li>

<li>
    <p>Файлы cookie, связанные с входом в систему</p>
    <p>Мы используем файлы cookie, когда вы входите в систему, чтобы мы могли помнить об этом факте. Это избавляет вас от необходимости входить в систему
         каждый раз, когда вы посещаете новую страницу. Эти файлы cookie обычно удаля
        ются или очищаются при выходе из системы, чтобы обеспечить доступ к ограниченным функциям и областям только при входе в систему.</p></li>




<li>
    <p>Файлы cookie, связанные с формами</p>
    <p>Когда вы отправляете данные через форму, такую ​​как те, которые находятся на страницах контактов или в формах коммента
    риев, файлы cookie могут быть настроены для запоминания ваших пользовательских данных для будущей переписки.</p>
</li>


</ul>

<p><strong>Сторонние файлы cookie</strong></p>

<p>В некоторых особых случаях мы также используем файлы cookie, предоставленные доверенными третьими сторонами. В следующем разделе подробно описаны файлы cookie третьих сторон, с которыми вы можете столкнуться на этом сайте.

</p>

<ul>

<li>
    <p>Этот сайт использует Google Analytics, которое является одним из самых распространенных и надежных аналитических решений в Интернете, помогающих нам понять, как вы используете сайт, и способы, которыми мы можем улучшить ваш опыт. Эти файлы cookie могут отслеживать такие вещи, как время, которое вы проводите на сайте, и страницы, которые вы посещаете, чтобы мы могли продолжать создавать интересный контент.
</p>
    <p>Для получения дополнительной информации о файлах cookie Google Analytics посетите официальную страницу Google Analytics.</p>
</li>

<li>
    <p>Сторонняя аналитика используется для отслеживания и измерения использования этого сайта, 
        чтобы мы могли продолжать создавать интересный контент. Эти файлы cookie могут отслеживать такие вещи, как время, которое вы проводите на сайте или страницах, которые вы посещаете, что помогает нам понять, как мы можем улучшить сайт для вас.</p>
</li>

<li>
    <p>Время от времени мы тестируем новые функции и вносим небольшие изменения в способ доставки сайта. Когда мы все еще тестируем новые функции, эти файлы cookie могут использоваться, чтобы гарантировать, что вы получаете постоянный опыт работы на сайте, и при этом мы понимаем, какие оптимизации наши пользователи ценят больше всего.</p>
</li>

<li>
    <p>Поскольку мы продаем продукты, для нас важно понимать статистику о том, сколько посетителей нашего сайта действительно совершают покупку, и поэтому именно такие данные будут отслеживать эти файлы cookie. Это важно для вас, так как это означает, что мы можем делать точные бизнес-прогнозы,
         которые позволяют нам отслеживать нашу рекламу и затраты на продукцию, чтобы обеспечить наилучшую возможную цену.</p>
</li>

</ul>

<p><strong>Больше информации</strong></p>

<p>Надеюсь, это прояснило вам ситуацию, и, как уже упоминалось ранее, если есть что-то, в чем вы не уверены, нужно ли вам или нет, обычно безопаснее оставить файлы cookie включенными, 
    если они действительно взаимодействуют с одной из функций, которые вы используете на нашем сайте.</p>

<p>Однако, если вы все еще ищете дополнительную информацию, вы можете связаться с нами одним из предпочитаемых нами способов связи:</p>

<ul>
<li>Почта: privacy@bizzarro74.ru</li>

</ul>

        </div>
    )
}
