import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listOrderMine } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

export default function OrderHistotyScreen(props) {
    const orderMineList = useSelector((state) => state.orderMineList);
    const { loading, error, orders } = orderMineList;
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(listOrderMine());
    }, [dispatch]);
    return (
        <div className="history">
           <h3>История заказов</h3>
           {loading ? (<LoadingBox></LoadingBox>) : error ? (<MessageBox>{error}<br/> Повторите попытку позже</MessageBox>) : (
 
 <table className="table table-noblock">
 <thead>
   <tr>
     <th>Номер заказа</th>
     <th>Дата заказа</th>
     <th>Общая сумма (руб)</th>
     <th>Подробно</th>
     <th>Подтверждение заказа</th>
   </tr>
 </thead>
 <tbody>
   {orders.map((order) => (
     <tr key={order._id}>
       <td>{order._id.substring(19)}</td>
       <td>{order.createdAt.substring(0,10).split('-').reverse().join('-')}</td>
       <td>{order.totalPrice.toFixed(2)}</td>
       <td>
         <button
           type="button"
           target="_blank"
           className="button btn-hist"
           onClick={() => {
             props.history.push(`/order/${order._id}`);
           }}
         >
           Заказ
         </button>
       </td>
       <td>{order.confirm ? 'Подтвержден' : 'Не подтвержден'}</td>
     </tr>
   ))}
 </tbody>
</table>)}
        </div>
    )
}
