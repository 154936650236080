import React, { useEffect, useState } from 'react';

export default function ButtonScrollToTop() {
    const [show, setShow] = useState(false);
    
    
    useEffect(() => {
          const checkIsBottomOfPage = () => {
            if (window.scrollY >= 1500) {
                setShow(true)
            } else {
              setShow(false);
            }
          }
          document.addEventListener("scroll", checkIsBottomOfPage);
          return () => {
            return document.removeEventListener("scroll", checkIsBottomOfPage)
        }
      }, [])

      const moveScroll = () => {
        window.scrollTo(0, 0);
      }

    return (
        <div className={show ? "btn-scroll show-btn" : "btn-scroll hide-btn"} onClick={() => {
            moveScroll()
        }}>
            <img className="btn-scroll-img" alt="up" src={window.location.origin + '/img/icons/up.png'}/>
            </div>
    )
}
