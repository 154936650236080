import React, { useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {listProducts} from '../../actions/productActions';
import Product from '../../components/Product';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';

export default function BlouseScreen(props) {
   const dispatch = useDispatch();
   const productList = useSelector(state => state.productList);
   const {loading,error, products} = productList;
   const [scrollPosit, setScrollPosit] = useState(0 || JSON.parse(localStorage.getItem('scroll')))

  const scrollSet = () => {
   if(scrollPosit) {
   window.scrollTo(0, scrollPosit);
   } 
   localStorage.removeItem('scroll');
   setScrollPosit(0)
   return
 }

  return  (<div onLoad={()=> {
   return scrollSet()
 }}>
      { loading ? <LoadingBox/> 
    : error ? <MessageBox>{error}<br/> Повторите попытку позже</MessageBox>
  : <div className="row center">
     {products.filter(x => x.category === 'аксессуар').map(product => (<Product key={product._id} product={product}/>))}
  </div>}
  </div>)}