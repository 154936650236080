import React, { useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {filterProductsAction, listProducts} from '../../actions/productActions';
import Product from '../../components/Product';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import {PRODUCT_FILTER_LIST_REMOVE} from '../../constans/productConstans';

export default function SkirtScreen(props) {
  const dispatch = useDispatch();
  const productList = useSelector(state => state.productList);
  const {loading,error, products} = productList;
  const filterProducts = useSelector(state => state.filterProducts);
  const {products: productFilter} = filterProducts;
  const [sizes, setSizes] = useState('' || JSON.parse(localStorage.getItem('size')));
   const [prices, setPrices] = useState('' || JSON.parse(localStorage.getItem('price')));
  const [scrollPosit, setScrollPosit] = useState(0 || JSON.parse(localStorage.getItem('scroll')))

  const handlerFilter = (sizesValue, pricesValue) => {
   dispatch(filterProductsAction(sizesValue, pricesValue));
   return productFilter;
 }

 const handlerRemoveFilter = () => {
   dispatch({type: PRODUCT_FILTER_LIST_REMOVE})
   setPrices('');
   setSizes('');
 }

 const scrollSet = () => {
  if(scrollPosit) {
  window.scrollTo(0, scrollPosit);
  } 
  localStorage.removeItem('scroll');
  setScrollPosit(0)
  return
}

  return  (<div onLoad={()=> {
    return scrollSet()
  }}>
      <form className="form-filter">
        <select value={sizes} onChange={e => {
          setSizes(e.target.value)
          localStorage.setItem('size', JSON.stringify(e.target.value))}} className="sizes">
          <option key='none' value=''>Выбрать размер</option>
          <option key='40' value='40'>40</option>
          <option key='42' value='42'>42</option>
          <option key='44' value='44'>44</option>
          <option key='46' value='46'>46</option>
          <option key='48' value='48'>48</option>
          <option key='50' value='50'>50</option>
        </select>
        <select value={prices} onChange={e => {
          setPrices(e.target.value)
          localStorage.setItem('price', JSON.stringify(e.target.value))}} className="prices">
          <option key='allprice' value=''>Отсортировать</option>
          <option key='min' value='min'>По возрастанию</option>
          <option key='max' value='max'>По убыванию</option>
        </select>
        <button onClick={(e) => {
          e.preventDefault();
          handlerFilter(sizes, prices);
        }} className="btn-filter-submit" type="submit">Применить фильтры</button>
        <button onClick={() => {
          handlerRemoveFilter()
          localStorage.removeItem('size')
          localStorage.removeItem('price')}} className="btn-filter-reset" type="reset">Сбросить</button>
        </form>
      { loading ? <LoadingBox/> 
    : error ? <MessageBox>{error}<br/> Повторите попытку позже</MessageBox>
  : <div className="row center">
     {productFilter ? (productFilter.length >= 1) ? productFilter.filter(x => x.category === 'юбка' || x.category === 'Юбка').map(product => (<Product key={product._id} product={product} />)) : (<p className="message-error">Поиск не дал результатов</p>) :
          products.filter(x => x.category === 'юбка' || x.category === 'Юбка').map(product => (<Product key={product._id} product={product}/>))}
  </div>}
  </div>)}