import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToBasket } from '../actions/cartActions';

export const useCart = () => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector(state => state.cart);
  const storageCartItems = localStorage.getItem('cartItems');

  useEffect(() => {
    if (storageCartItems && !cartItems.length) {
      JSON.parse(storageCartItems).forEach((productCart) => {
        const { product: productId, size, color } = productCart;
        dispatch(addToBasket(productId, size, color))
      })
    }
  }, []);

  return { cartItems }
}
