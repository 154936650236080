import React from 'react';
import { Link } from 'react-router-dom';

export default function CatalogScreen() {
  
  return (
    <div className="row center">
     <Link className="link-style" to="/new"><img className="medium" alt="new" src={window.location.origin + '/img/catalog-screen/new.jpg'}/></Link>
     <Link className="link-style" to="/accessory"><img className="medium" alt="acce" src={window.location.origin + '/img/catalog-screen/acce.jpg'}/></Link>
     <Link className="link-style" to="/blouse"><img className="medium" alt="blouse" src={window.location.origin + '/img/catalog-screen/blouse.jpg'}/></Link>
     <Link className="link-style" to="/pants&jumpsuits" ><img className="medium" alt="pants" src={window.location.origin + '/img/catalog-screen/pants.jpg'}/></Link>
     <Link className="link-style" to="/jackets&vests"><img className="medium" alt="jacket" src={window.location.origin + '/img/catalog-screen/jacket.jpg'}/></Link> 
     <Link className="link-style" to="/coats&cloaks"><img className="medium" alt="coat" src={window.location.origin + '/img/catalog-screen/coat.jpg'}/></Link>
     <Link className="link-style" to="/dress"><img className="medium" alt="dress" src={window.location.origin + '/img/catalog-screen/dress.jpg'}/></Link> 
     <Link className="link-style" to="/skirts"><img className="medium" alt="skirts" src={window.location.origin + '/img/catalog-screen/skirt.jpg'}/></Link>
     <Link className="link-style" to="/shirt"><img className="medium" alt="blouse" src={window.location.origin + '/img/catalog-screen/shirt.jpg'}/></Link>
    </div>)
  }
