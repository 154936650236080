import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signout } from '../actions/signinActions';
import { PRODUCT_FILTER_LIST_REMOVE } from '../constans/productConstans';
import { useCart } from '../hooks/useCart';

export default function Header(props) {
  const {cartItems} = useCart();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dispatch = useDispatch();
  let {show, setShow} = props;

  const signoutHandler = () => {
    dispatch(signout());
  };

  const handleClick = () => {
    if(show) setShow(!show)
    localStorage.removeItem('scroll');
    dispatch({type: PRODUCT_FILTER_LIST_REMOVE});
    localStorage.removeItem('size');
    localStorage.removeItem('price');
  }
  
  return (
     <header>  
      <div className="logo-wrap">
        <Link to="/">
          <img onClick={handleClick} className="header-logo" alt="logo" src={window.location.origin + '/img/logo.png'}/>
        </Link>
      </div>
       <div className={show ? "navbar show" : "navbar hide"}>
         <div className="dropdown">
          <Link onClick={handleClick} className="add-padd" to="/catalog">Каталог</Link>
                <ul className="dropdown-content" onClick={handleClick}>
                  <li>
                    <Link to="/new">Новинки</Link>
                  </li>
                  <li>
                    <Link to="/accessory">Аксессуары</Link>
                  </li>
                  <li>
                    <Link to="/blouse">Блузки</Link>
                  </li>
                  <li>
                    <Link to="/shirt">Футболки и лонгсливы</Link>
                  </li>
                  <li>
                    <Link to="/pants&jumpsuits">Брюки и комбинезоны</Link>
                  </li>
                    <li>
                    <Link  to="/coats&cloaks">Верхняя одежда</Link>
                  </li>
                  <li>
                    <Link  to="/jackets&vests">Жакеты и жилеты</Link>
                  </li>
                  <li>
                    <Link to="/dress">Платья</Link>
                  </li>
                  <li>
                    <Link to="/skirts">Юбки</Link>
                  </li>
                </ul>
              </div>
         <Link  onClick={handleClick} to="/cart">Корзина
         {cartItems.length > 0 && (
           <span className="cart-length">{cartItems.length}</span>
         )}
         </Link >
         
          {userInfo ? (
              <div className="dropdown">
                {show ? null : <Link onClick={handleClick} to="#">
                  {userInfo.name.match(/.*?\s/g)}
                </Link>}
                <ul className="dropdown-content" onClick={handleClick}>
                  <li>
                    <Link to="/profile">Профиль</Link>
                  </li>
                  <li>
                    <Link to="/orderhistory">История заказов</Link>
                  </li>
                  <li>
                    <Link to="#signout" onClick={signoutHandler}>
                      Выйти
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              <Link className="add-padd" onClick={handleClick} to="/signin">Войти</Link>
            )}
       {userInfo && userInfo.isAdmin && (
              <div className="dropdown">
                {show ? null : <Link to="#admin">Админ.панель</Link>} 
                <ul className="dropdown-content" onClick={handleClick}>
                  <li>
                    <Link to="/dashboard">Список товаров</Link>
                  </li>
                  <li>
                    <Link to="/productlist">Товары</Link>
                  </li>
                  <li>
                    <Link to="/orderlist">Заказы</Link>
                  </li>
                  <li>
                    <Link to="/userlist">Пользователи</Link>
                  </li>
                </ul>
              </div>
            )}

      </div>    
        <div className={show ? "burger burger-line": "burger"} onClick={() => {
          setShow(!show)}}>
          <span className={show ? "line rotate-1 white-line" : "line"}></span>
          <span className={show ? "line rotate-2 white-line" : "line"}></span>
          <span className={show ? "line delete" : "line"}></span>
        </div>
        <Link  className="hide-cart" to="/cart">
        <img className="cart-img" src={window.location.origin + '/img/icons/cart.svg'} alt="cart"/>
         {cartItems.length > 0 && (
           <span className="cart-length">{cartItems.length}</span>
         )}
         </Link >
    </header>
  )
}
