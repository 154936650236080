import React from 'react';

export default function Footer(props) {
  let {show, setShow} = props;
  return (
    <footer className={show ? "blur" : null} onClick={() => {
      setShow(false)
    }}>
      <div className="copyright">
        <ul>
        <li>ИП Дубровский В. А.</li>
        <li>ИНН 0744815291177</li>
        <li>ОГРНИП 315744800001291</li>
        <li>Адрес: ТК Фиеста, Челябинск, Молодогвардейцев, 7</li>
        <li>Время работы: ежедневно с 10<sup>00</sup> по 22<sup>00</sup></li>
        </ul>
      </div>
      <div className="footer-right">
      <div className="icons-wrapper">
      <div className="icons"><a href="mailto:support@bizzarro74.ru"><img className="icon" alt="email" src={window.location.origin + '/img/icons/email.png'}/></a></div>
      <div className="icons"><a target="_blank" rel="noreferrer nofollow" href="https://vm.tiktok.com/ZSJwsu8tc/"><img className="icon" alt="tik-tok" src={window.location.origin + '/img/icons/tik-tok.png'}/></a></div>
      <div className="icons"><a target="_blank" rel="noreferrer nofollow" href="https://vk.com/bizzarro74"><img className="icon" alt="vk" src={window.location.origin + '/img/icons/vk.png'}/></a></div>
      </div>
      <p>&#169; 2021-{new Date().getFullYear() || ""} Все права защищены </p>
      </div>
    </footer>
  )
}
