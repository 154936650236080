import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmOrder, listOrderHistory, orderCancelBD, orderDeleteBD} from '../../actions/orderActions';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';

export default function ListOrder(props) {
    const dispatch = useDispatch();
    const orderHistoryList = useSelector(state => state.orderHistoryList);
    const { loading, error, orders} = orderHistoryList;
    const orderConfirm = useSelector(state => state.orderConfirm);
    const {success} = orderConfirm;
    const orderCancel = useSelector(state => state.orderCancel);
    const {success: sucStatus} = orderCancel;
 
    useEffect(() => {
        dispatch(listOrderHistory());
    },[dispatch, success])

    const handlerCancelOrder = (order) => {
        //отмена заказа
        dispatch(orderCancelBD(order));
        if(sucStatus) dispatch(listOrderHistory());
       }

    const handlerConfirmOrder = (id) => {
        // подтверждение заказа
    dispatch(confirmOrder(id));
    if(success) dispatch(listOrderHistory());
    }

    const handlerDeleteOrder = (id) => {
        // удаление заказа
        dispatch(orderDeleteBD(id));
        dispatch(listOrderHistory());
    }

    return (
        <div className="history">
        <h3>История заказов</h3>
        {loading ? (<LoadingBox></LoadingBox>) : error ? (<MessageBox>{error}</MessageBox>) : (
        <table className="table">
        <thead>
        <tr>
        <th>Номер заказа</th>
        <th>Дата заказа</th>
        <th>Заказ</th>
        <th>Общая сумма (руб)</th>
        <th>Имя пользователя</th>
        <th>Номер пользователя</th>
        <th></th>
        <th></th>
        <th></th>
        </tr>
        </thead>
        <tbody>
        {orders.map((order) => (
        <tr key={order._id}>
            <td>{order._id.substring(19)}</td>
            <td>{order.createdAt.substring(0,10).split('-').reverse().join('-')}</td>
            <td>{order.orderItems.map(x => (<p key={x._id}><span>{x.name} </span><span>{x.article} </span><span>{x.color} </span><span>{x.size} р.</span></p>))}</td>
            <td>{order.totalPrice.toFixed(2)}</td>
            <td>{order.userName}</td>
            <td>{order.userPhone}</td>
            <td>{order.confirm ? 'Подтверждeно' : (<button onClick={e => {
                handlerConfirmOrder(order._id)
            }} className="btn-filter-submit btn-mini">Подтвердить</button>)}</td>
            {order.status ? (<td><button onClick={e => {
               handlerCancelOrder(order)}} className="btn-filter-submit btn-mini">Отмена заказа</button></td>) : <td>Отменен</td> }
               <td><button onClick={e => {
               handlerDeleteOrder(order._id)}} className="btn-filter-submit btn-mini">Удалить</button></td>
        </tr>))}
        </tbody>
        </table>)}
            </div>
 )
}
